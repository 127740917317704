:root {
  --colorWhite: #FEFAE0;
  --colorBlack: #283618;
  --colorOrange: #DDA15E;
  --background-color: var(--colorWhite);
  --colorPrimary: var(--colorBlack);
  --colorSecondary: var(--colorOrange);
}

.app {
  text-align: center;
  padding: 2rem 2rem;
}

.pigments header {
  margin-bottom: 2rem;
}

.pigments h1 {
  color: var(--colorSecondary);
  margin: 0;
}

.playarea {
  display: flex;
  flex-direction: row;
}

.container {
  padding: 0 2rem;
}

#file {
  display: none;
}

form {
  display: flex;
  justify-content: flex-end;
}

.file-upload label {
  display: flex;
  width: 10rem;
  font-size: 1rem;
  justify-content: space-around;
  border: 6px solid var(--colorPrimary);
  /* font-family: 'Quicksand', sans-serif; */
  font-weight: 400;
  border-radius: 2px;
  padding: 5px 8px;
  cursor: pointer;
}
.file-upload label:hover, .file-upload label:focus {
  color: var(--colorSecondary);
  border-color: var(--colorSecondary);
}

.file-upload img {
  width: 1.25rem;
}
/* UPLOAD */

.upload {
  flex: 1;
  text-align: right;
}
.upload .preview {
  margin-top: 2rem;
}
.upload .preview img {
  max-width: 100%;
}

/* SUMMERY */

.summery {
  flex: 1;
}

.summery .palette {
  margin-bottom: 2rem;
}

/* SWATCHES */

.swatches {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.swatch {
  display: flex;
  flex: 1;
  padding: 0.5rem 1.75rem;
  border: 4px solid transparent;
}
.swatch:hover {
  border-color: var(--colorBlack);
  cursor: pointer;
}
.swatch.dominant {
  width: 100%;
  flex: unset;
}

.ball {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #ddd;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}
.ball.copied {
  box-shadow: -2.5px -2.5px 20px 2.5px #cdd9e5, 2.5px 2.5px 20px 5px #df8039;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.links {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 8px;
}

@media only screen and (max-width: 768px) {
  .playarea {
    flex-direction: column;
  }

  .upload, .summery {
    text-align: center;
    border: none;
  }

  .container {
    padding: 0;
  }

  .file-upload  {
    align-items: center;
  }

  form {
    justify-content: center;
  }
}

@media only screen and (min-width: 1400px) {
  .summery .container{
    padding: 4.5rem 10rem;
  }

  .upload .preview img {
    max-width: 33vw;
    max-height: 50vh;
  }
}


@media only screen and (min-width: 768px) {
  .upload .preview img {
    max-height: 50vh;
  }
}
