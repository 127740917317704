@font-face {
  font-family: 'psychedelic';
  src:  local('psychedelic'), url('./fonts/psychedelic.otf') format('opentype');
}

@font-face {
  font-family: 'psyche';
  src:  local('psyche'), url('./fonts/pyshce.ttf') format('truetype');
}


@font-face {
  font-family: 'j-airplane';
  src:  local('j-airplane'), url('./fonts/J-airplane-swash-font.ttf') format('truetype')
}


body {
  background-color: var(--background-color);
  color: var(--textColor);
  margin: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.padding-around-s {
  padding: 0 6px;
}

/* TYPOGRAPHY */

h1, h2, h3 {
  font-weight: 400;
}

h1 {
  font-family: "psyche";
  font-size: 4rem;
}

h2 {
  font-family: 'Quicksand', sans-serif;
  font-size: 2.5rem;
}

h3 {
  font-family: 'Quicksand', sans-serif;
  font-size: 2rem;
}

a {
  text-decoration: none;
  color: var(--textColor);
}